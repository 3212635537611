import { useEffect } from 'react'
import { useRouter } from 'next/router'

const useMobileTheme = () => {
  const router = useRouter()

  useEffect(() => {
    const lastParam = router.asPath.split('/').pop()
    if (lastParam === 'mobile') {
      document.body.classList.add('mobile-theme')
    } else {
      document.body.classList.remove('mobile-theme')
    }
  }, [router.asPath])
}

export default useMobileTheme
