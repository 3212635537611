import { useEffect } from 'react'
import Script from 'next/script'

const InterstitialAd = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.googletag = window.googletag || { cmd: [] }

      window.googletag?.cmd?.push(() => {
        const interstitialSlot = window.googletag.defineOutOfPageSlot(
          '/23202123844/oneturf.news_interstitial',
          window.googletag.enums.OutOfPageFormat.INTERSTITIAL
        )

        if (interstitialSlot) {
          interstitialSlot.addService(window.googletag.pubads()).setConfig({
            interstitial: {
              triggers: {
                navBar: true,
                unhideWindow: true
              }
            }
          })

          // window.googletag.pubads().addEventListener('slotOnload', (event) => {
          //   if (interstitialSlot === event.slot) {
          //     console.log('Interstitial ad loaded.')
          //   }
          // })

          window.googletag.pubads().enableSingleRequest()
          window.googletag.enableServices()
          window.googletag.display(interstitialSlot)
        }
      })
    }
  }, [])

  return (
      <Script
        strategy="afterInteractive"
        src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        crossOrigin="anonymous"
      />
  )
}

export default InterstitialAd
